import React, {Component} from "react";
import {currentLanguageByPath} from "../../../helpers/MainHelper";
import Section from "../../atoms/Section/Section";
import CardItem from "../../molecules/CardItem/CardItem";
import hljs from 'highlight.js/lib/core';
import 'highlight.js/scss/default.scss';
import './SnippetList.scss';

hljs.registerLanguage('php', require('highlight.js/lib/languages/php'));

class SnippetList extends Component {
    render() {
        let items = this.props.data.drupal.entityQuery.items;
        const cards = items.map((element) => {
            let item = element.nl;
            if (currentLanguageByPath() === 'en') {
                item = element.en;
            }

            if (item === null) {
                return '';
            }

            let path = item.path.alias;

            if (currentLanguageByPath() === 'en') {
                path = '/en' + path;
            }

            const codeSnippet = "<pre class='hljs'>" + hljs.highlight('php', item.fieldCodesnippet).value + "</pre>";

            return (
                <CardItem
                    key={item.nid}
                    nid={item.nid}
                    class={'snippet-item'}
                    title={item.title}
                    content={(item.body ? item.body : '') + codeSnippet}
                    link={path}
                    image={''}
                    cardWidth={this.props.cardWidth}
                />
            );
        })

        return (
            <Section className={'section-cards'}>
                {cards}
            </Section>
        );
    };
}

export default SnippetList;
