import React from "react";
import Page from '../components/molecules/NodeTypes/page';
import Layout from "../components/templates/Layout";
import {graphql} from "gatsby";

const NodePage = ({data}) => (
    <Layout
        lang={data.drupal.page.langcode}
        class={'home'}
        metatags={data.drupal.page.url.metatags}
        title={data.drupal.page.title}
        activeNid={data.drupal.page.nid}
    >
        <Page nodeContent={data.drupal.page} />
    </Layout>
);

export default NodePage;

export const query = graphql`
query page($id: ID!, $language: Drupal_Langcode!) {
    drupal {
        page: entityById(id: $id, langcode: $language, entityType: NODE) {
            ... on Drupal_NodePage {
                nid
                title
                body 
                fieldOpening 
                path {
                    alias
                }
                langcode
                url {
                    ... on Drupal_EntityUrl {
                        metatags {
                            tag
                            attributes {
                                key
                                value
                            }
                        }
                    }
                }
            }
        }
    }
}
`;
