import React, {Component} from "react";
import Parser from 'html-react-parser';
import Header from "../../atoms/header/header";
import SkillCategoryListWithData from "../../organisms/SkillList/SkillCategoryList.query";
import {currentLanguageByPath, getGreeting} from "../../../helpers/MainHelper";
import SnippetListWithData from "../../organisms/SnippetList/SnippetList.query";

class Page extends Component {
    render() {
        let page = this.props.nodeContent;
        let opening = '';
        let body = '';
        let title = '';

        if (page.nid === 1) {
            title = getGreeting(currentLanguageByPath());
        }
        else {
            if (this.props.title) {
                title = this.props.title;
            }
            else {
                title = page.title;
            }
        }


        if (page.fieldOpening !== undefined && page.fieldOpening !== null) {
            opening = page.fieldOpening;
        }

        if (page.body !== undefined && page.body !== null) {
            body = page.body;
        }

        return <>
            <Header page_title={title}>
                {opening}
            </Header>
            <div>
                {Parser(body)}
            </div>
            { page.nid === 44 ? <SkillCategoryListWithData /> : '' }
            { page.nid === 55 ? <SnippetListWithData /> : ''}
            </>
    }
}

export default Page;
