import React from "react";
import './SkillLevel.scss';

const SkillLevel = (props) => {
    let classList = 'skill';

    if (props.classOn) {
        classList += ' on';
    }

    if (props.classHalf) {
        classList += ' half';
    }
    return (
        <span className={classList} />
    );
};

export default SkillLevel;
