import React, {Component} from "react";
import H3 from "../../atoms/headings/h3";
import './SkillCategoryList.scss';
import SkillList from "./SkillList";
import {currentLanguageByPath} from "../../../helpers/MainHelper";

class SkillCategoryList extends Component {

    render() {
        let skills = this.props.data.drupal.termParents.items.map((term) => {
            if (currentLanguageByPath() === 'en') {
                term = term.en;
            }
            else {
                term = term.nl;
            }
            return (
                <li key={term.id}>
                    <H3>{term.label}</H3>
                    <SkillList children={this.props.data.drupal.termChildren} parent={term.id} />
                </li>
            )
        });

        return (
            <ul className={'skill-category'}>
                {skills}
            </ul>
        )
    }
};

export default SkillCategoryList;

