import React, {Component} from "react";
import SkillCategoryList from "./SkillCategoryList";
import {graphql, StaticQuery} from "gatsby";

class SkillCategoryListWithData extends Component {
    render() {
        return (
            <StaticQuery query={
                graphql`
                {
                    drupal {
                        termParents: entityQuery(
                            entityType: TAXONOMY_TERM
                            filter:{
                                conditions:{
                                    operator:IN, field:"tid", value: ["27", "35", "39"]
                                }
                            }, 
                            limit: 1000
                        ) {
                            items {
                                ... on Drupal_EntityTranslatable {
                                    en: translation(langcode:EN) {
                                        ... on Drupal_TaxonomyTermSkills {
                                            id
                                            label
                                            langcode
                                        }
                                    }
                                    
                                    nl: translation(langcode:NL) {
                                        ... on Drupal_TaxonomyTermSkills {
                                            id
                                            label
                                            langcode
                                        }
                                    }
                                }
                            }
                        }
                        termChildren: entityQuery(
                            entityType: TAXONOMY_TERM
                            filter: {
                                conditions: {
                                    operator: IN, field: "parent", value: ["27", "35", "39"]
                                }
                            },
                            limit: 1000
                        ) {
                            items {
                                id
                                label
                                ... on Drupal_TaxonomyTermSkills {
                                    fieldLevel {
                                        value
                                    }
                                    parent {
                                        id
                                    }
                                }
                            }
                        }
                    }
                }
              `}
                         render={data => (
                             <SkillCategoryList data={data} />
                         )} />
        )
    }
}

export default SkillCategoryListWithData;
