import React from "react";
import SkillLevel from "../../atoms/SkillLevel/SkillLevel";

const Skill = (props) => {
    let level = props.level / 10;
    let skillLevel = [];

    for (var i = 1; i <= level; i++) {
        var listKey = i - 1;
        skillLevel.push(<SkillLevel key={'on' + listKey} classOn={true} classHalf={false} />);
    }

    if (level % 1 === 0.5) {
        skillLevel.push(<SkillLevel key={'on-half-' + level} classOn={false} classHalf={true} />);
    }

    let remaining = (50 - parseInt(props.level)) / 10;
    if (remaining >= 1) {
        for (var j = 1; j <= remaining; j++) {
            skillLevel.push(<SkillLevel key={'off-' + j} classOn={false} classHalf={false}/>);
        }
    }


  return (
      <>
          <span className={'skill-title'}>{props.label}</span>
          <div className={'skill-level'}>{skillLevel}</div>
      </>
  )
};

export default Skill;
