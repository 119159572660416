import React, {Component} from "react";
import {graphql, StaticQuery} from "gatsby";
import SnippetList from "./SnippetList";

class SnippetListWithData extends Component {
    render() {
        return (
            <StaticQuery query={graphql`
            {
                drupal {
                    entityQuery(
                        entityType: NODE
                        filter: {
                            conditions: [
                                {operator: EQUAL, field: "status", value: "1"}
                                {operator: EQUAL, field: "type", value: "snippet"}
                            ]
                        },
                        limit: 1000
                    ) {
                        items {
                            ... on Drupal_EntityTranslatable {
                                nl: translation(langcode: NL) {
                                    ... on Drupal_NodeSnippet {
                                        ... SnippetFields
                                    }
                                }
                                en: translation(langcode: EN) {
                                    ... on Drupal_NodeSnippet {
                                        ... SnippetFields
                                    }
                                }
                            }                
                        }
                    }
                }
            }
            `}
                         render={data => (
                             <SnippetList data={data} cardWidth={100} />
                         )}
            />
        )
    }
}

export default SnippetListWithData;

export const SnippetFields = graphql`
fragment SnippetFields on Drupal_NodeSnippet {
    nid
    title
    body 
    fieldCodesnippet
    fieldTags {
        id
        label
    }
    path {
        alias
    }
}
`;
