import React from "react";
import Skill from "../../molecules/Skill/Skill";
import './SkillList.scss';

const SkillList = (props) => {
    let skillList = props.children.items.map((term) => {
        if (term.parent[0].id === props.parent) {
            return (
                <li key={term.id}>
                    <Skill label={term.label} level={term.fieldLevel.value}/>
                </li>
            )
        }
        else {
            return '';
        }
    });
    return (
        <ul className={'skill-list'}>
            {skillList}
        </ul>
    );
};

export default SkillList;
